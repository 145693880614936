(function () {
    'use strict';

    angular.module('adminApp')
        .controller('FeatureController', FeatureController);

    FeatureController.$inject = ['$scope', '$templateCache', 'aerosAdminApi', 'AddEditAdminFeatureModal',
        'DeleteAdminFeatureModal', 'features'];

    function FeatureController($scope, $templateCache, aerosAdminApi, AddEditAdminFeatureModal, DeleteAdminFeatureModal,
                               features) {

        angular.extend($scope, {
            openAddEditAdminFeatureModal: openAddEditAdminFeatureModal,
            openDeleteAdminFeatureModal: openDeleteAdminFeatureModal
        });


        (function prepareAgGrid() {
            var defaultWidth = 250,
                template = $templateCache.get('featureGridActions.html');

            var columnDefs = [
                {
                    headerName: 'Name', field: 'name', width: defaultWidth
                }, {
                    headerName: 'Description', field: 'description', width: defaultWidth * 3
                }, {
                    headerName: 'Actions',
                    field: 'actions',
                    template: template,
                    width: 94
                }
            ];

            $scope.gridOptions = {
                columnDefs: columnDefs,
                enableSorting: true,
                enableFilter: true,
                rowSelection: 'multiple',
                enableColResize: true,
                angularCompileRows: true,
                rowHeight: 46,
                onRowClicked: function ($event) {
                }
            };


        })();

        $scope.$watch("commonReady", function (newVal) {

    		if (!$scope.allow['editFeatures']) return $scope.reject();
        	
            $scope.features = features.data.features;
            loadFeatures();
            
            

        });

        function loadFeatures() {
            $scope.rowData = [];

            angular.forEach($scope.features, function (feature) {

                $scope.rowData.push({
                    name: feature.name,
                    description: feature.description
                });

            });

            $scope.gridOptions.api.setRowData($scope.rowData);
        }

        function openAddEditAdminFeatureModal(feature) {
            AddEditAdminFeatureModal.open(feature).then(function(modalResults) {
                aerosAdminApi.featureApi.list().then(function(results) {
                    $scope.features = results.data.features;
                    loadFeatures();
                })
            });
        }

        function openDeleteAdminFeatureModal(feature) {
            DeleteAdminFeatureModal.open(feature).then(function(modalResults) {
                aerosAdminApi.featureApi.list().then(function(results) {
                    $scope.features = results.data.features;
                    loadFeatures();
                })
            });
        }
    }
})();